<template>
  <component
    :is="component"
    :to="isLinkHidden ? undefined : path"
    class="a-stories"
    :class="{ seen }"
    :no-prefetch="isLinkHidden ? undefined : true"
    @click="isLinkHidden ? $router.push(getUrl(path)) : null"
  >
    <AImage
      :src="src"
      :mobile-src="mobileSrc"
      provider="public"
      with-retina
      width="148"
      height="220"
      with-webp
    />
    <div class="a-stories__overlay" />
  </component>
</template>

<script lang="ts">
import { defineComponent, resolveComponent } from 'vue'
import { useNavigate } from '@/composables/useNavigate'
import AImage from '@/components/atoms/Image/AImage.vue'

export default defineComponent({
  name: 'AStories',
  components: { AImage },
  props: {
    src: {
      type: String,
      required: true
    },
    mobileSrc: {
      type: String,
      default: null
    },
    seen: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const { getUrl } = useNavigate()
    const isLinkHidden = props.path.includes('_ll')
    const component = isLinkHidden ? 'div' : resolveComponent('NuxtLink')

    return { getUrl, isLinkHidden, component }
  }
})
</script>

<style lang="postcss">
.a-stories {
  position: relative;
  width: 9.75rem;
  padding: var(--spacer-5xs);
  border: var(--spacer-5xs) solid var(--primary);
  border-radius: var(--border-radius-lg);
  cursor: pointer;
  aspect-ratio: 156 / 228;
  mix-blend-mode: multiply;

  &.seen {
    border-color: var(--color-neutral-300);
  }

  & > picture {
    display: flex;
    align-items: center;
    overflow: hidden;
    border-radius: var(--border-radius-base);
    transition: 0.3s linear;
  }

  & img {
    object-fit: cover;
    max-height: 100%;
    aspect-ratio: 148 / 220;
  }

  & > .a-stories__overlay {
    position: absolute;
    border-radius: var(--border-radius-base);
    background-color: var(--bg-shadow-base);
    opacity: 0;
    transition: opacity 0.3s linear;
    inset: 0.125rem;
  }

  @media (hover: hover) and (--screen-lg) {
    &:hover {
      & > picture {
        transform: scale(1.01);
      }

      & > .a-stories__overlay {
        opacity: 1;
      }
    }
  }

  @media (--screen-xs) {
    & img {
      aspect-ratio: 128 / 192;
    }
  }
}
</style>
