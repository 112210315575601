<template>
  <OCarouselOffer
    :title="$t('pages.main.actual')"
    :break-points="{
      mobile: 7,
      laptop: 6,
      desktop: 8,
    }"
  >
    <AStories
      v-for="story in stories"
      :key="story.id"
      :src="story.src"
      :path="getAbsoluteUrl(story.path)"
      :seen="isStorySeen(story.id)"
      @click="onClickStory(story.id)"
    />
  </OCarouselOffer>
</template>

<script lang="ts" setup>
import { defineComponent, onMounted } from 'vue'
import { useLocalStorage } from '#imports'
import { getUrlWithTrailingSlashes } from '@/utils/url'

import AStories from '@/components/atoms/Stories/AStories.vue'
import OCarouselOffer from '@/components/organisms/CarouselOffer/OCarouselOffer.vue'

defineComponent({ name: 'HomeStories' })

// Stories
const seenStorage = useLocalStorage('stories')
const seenStories = ref<number[]>([])
const isStorySeen = (id: number): boolean => seenStories.value.includes(id)

const onClickStory = (id: number): void => {
  if (isStorySeen(id)) { return }
  seenStories.value.push(id)
  seenStorage.value = JSON.stringify(seenStories.value)
}

const getAbsoluteUrl = (url: string) => {
  const endWithSlash = !url.endsWith('.html')
  return getUrlWithTrailingSlashes(url, endWithSlash)
}

const { data: stories } = await useHydrationData('home-stories', async () => {
  function getStories () {
    return [
      {
        id: 1,
        src: '/assets/images/stories/9_148x220.png',
        path: '/wine/red/white/with-fish-grill/with-grilled-meat/with-meat-snacks/with-smoked-fish_ll/'
      },
      {
        id: 2,
        src: '/assets/images/stories/10_148x220.png',
        path: '/wine/new-world-wines/'
      },
      {
        id: 3,
        src: '/assets/images/stories/11_148x220.png',
        path: '/wine/90-rating/'
      },
      {
        id: 4,
        src: '/assets/images/stories/12_148x220.png',
        path: '/wine/new_ll/'
      },
      {
        id: 5,
        src: '/assets/images/stories/13_148x220.png',
        path: '/wine/italy/1000-1500rub/500-1000rub/500rub_ll/'
      },
      {
        id: 6,
        src: '/assets/images/stories/14_148x220.png',
        path: '/liqueur/with-cocktails/'
      },
      {
        id: 7,
        src: '/assets/images/stories/15_148x220.png',
        path: '/wine-set/'
      },
      {
        id: 8,
        src: '/assets/images/stories/16_148x220.png',
        path: '/pages/franchise.html'
      }
    ]
  }

  return await getStories()
}, { default: [], lazy: true })

onMounted(() => {
  seenStories.value = seenStorage.value?.length ? JSON.parse(seenStorage.value) : []
})
</script>
